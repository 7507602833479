<template>
  <div class="content md flex-box vertical hr-desktop">
    <a-tabs :active-key="key" tab-position="left" style="height: 100%" @tabClick="handleClick">
      <a-tab-pane :key="1" tab="评价">
        <hr-ass-approve style="height: 100%;"></hr-ass-approve>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="审批">
        <div class="tab-pane-box">
          <hr-approve style="height: 100%;"></hr-approve>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import HrApprove from "./workbench/layouts/HRApprove";
import HrAssApprove from "./workbench/layouts/HRAssApprove";

export default {
  name: "HRDesktop",
  components: {
    HrApprove,
    HrAssApprove
  },
  data() {
    return {
      key: 1,
    }
  },
  methods: {
    handleClick(e) {
      this.key = e;
    },
  }
}
</script>

<style lang="less">
  .hr-desktop {
    .ant-tabs-content,
    .ant-tabs-tabpane,
    .tab-pane-box {
      height: 100%;
    }
    .ant-tabs-left-content > .ant-tabs-tabpane-inactive {
      height: 0;
    }
  }
</style>
